import "@coso/layout/external-dependencies";
import "@coso/layout/external-dependencies/jquery-validation";
import "flatpickr";
import { ThemeManager } from "@coso/layout";
import { defineCustomElements as defineLayoutElements } from "@coso/layout/loader";
import { defineCustomElements as defineSaasElements } from "@coso/saas/loader";

defineLayoutElements();
defineSaasElements();

var layoutElement = document.querySelector("coso-layout");

layoutElement.addEventListener("componentLoaded", (event) => {
  const container = document.getElementById("site");
  const loadingElement = document.querySelector(".loading-progress");
  layoutElement.className = "visible";
  container.removeChild(loadingElement);
});

layoutElement.isMvc = window.isMvc;
layoutElement.isAuthenticated = window.isAuthenticated;
layoutElement.identityProviderEndpoint = window.identityProviderEndpoint;
layoutElement.shellEndpoint = window.shellEndpoint;
layoutElement.dashboardEndpoint = window.dashboardEndpoint;
layoutElement.menuLogoHref = "/";
layoutElement.tenantName = window.tenantName;
layoutElement.userName = window.userName;
layoutElement.supportsMultiTheme = false;

const footerElement = document.querySelector("coso-footer");
footerElement.contactUsLink = {
  href: "https://portal.connecting-software.com/support/create-case/?subjectid=" + window.portalSubjectId + "&cs_deploymenttype=768880000&tenantid=" + window.tenantId,
  target: "_blank",
};

footerElement.termsAndConditionsLink = {
  href: window.portalEndpoint + "/support/SaaSServiceAgreement",
  target: "_blank",
};

footerElement.cookiesLink = {
  href: window.portalEndpoint + "/support/SaaSCookiesPolicy",
  target: "_blank",
};

footerElement.releaseNotesLink = { href: "/Support/ReleaseNotes" };
footerElement.productVersion = "19.9.0";

ThemeManager.instance.theme$.subscribe((theme) => {
  const helperIframe = document.getElementById("helpSliderIframe");
  let serviceId = 0;
  switch (window.productArea) {
    case "PortOfTrust": {
      serviceId = 9;
      break;
    }
  }
  helperIframe.src = window.shellEndpoint + "HelpSlider?serviceId=" + serviceId + "&theme=" + theme;
});
